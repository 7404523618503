import Vue from "vue";
import Router from "vue-router";
import { profile } from '../api';
import store from '../store/Store';

Vue.use(Router);

const requireAuth = () => async (from, to, next) => {
  let accessToken = await localStorage.getItem('ACCESS_TOKEN');
  if(accessToken == null){
    return next("/login");
  }
  else{
    let profileRes = await profile();
    if(profileRes.error == true){
      await localStorage.removeItem("ACCESS_TOKEN");
      await localStorage.removeItem("REFRESH_TOKEN");
      return next("/login");
    }else{
      await store.commit("SET_APT_NAME", profileRes.aptName + " (" + profileRes.aptAddr + ")");
      await store.commit("SET_USER_NAME", profileRes.userName);
      await store.commit("SET_USER_ID", profileRes.userId);
      await store.commit("SET_APT_ID", profileRes.aptId);
      return next();
    }
  }
}

const noRequireAuth = () => async (from, to, next) => {
  let accessToken = await localStorage.getItem('ACCESS_TOKEN');
  if(accessToken == null){
    return next();
  }
  else
    return next('/starter');
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      redirect: "/starter",
      component: () => import("@/layouts/full-layout/FullLayout"),
      beforeEnter: requireAuth(),
      children: [
        {
          name: "홈",
          path: "starter",
          component: () => import("@/views/Starter"),
        },
        {
          name: "설정",
          path: "settings",
          component: () => import("@/views/Settings"),
        },
        {
          name: "세대 관리",
          path: "unit",
          component: () => import("@/views/Unit"),
        },
        {
          name: "대표계정 요청 관리",
          path: "unitPending",
          component: () => import("@/views/UnitPending"),
        },
        {
          name: "공지사항 관리",
          path: "notice",
          component: () => import("@/views/Notice"),
        },
        {
          name: "공지사항 작성",
          path: "noticeWrite",
          component: () => import("@/views/NoticeWrite"),
        },
        {
          name: "주민투표 관리",
          path: "vote",
          component: () => import("@/views/Vote"),
        },
        {
          name: "투민투표 작성",
          path: "voteWrite",
          component: () => import("@/views/VoteWrite"),
        },
        {
          name: "민원/하자 관리",
          path: "request",
          component: () => import("@/views/Request"),
        },
        {
          name: "민원/하자 보기",
          path: "requestView",
          component: () => import("@/views/RequestView"),
        },
        {
          name: "시설예약 목록",
          path: "place",
          component: () => import("@/views/Place"),
        },
        {
          name: "시설예약 실태",
          path: "placeBill",
          component: () => import("@/views/PlaceBill"),
        },
        {
          name: "방문차량 목록",
          path: "carVisit",
          component: () => import("@/views/CarVisit"),
        },
        {
          name: "준비중",
          path: "notReady",
          component: () => import("@/views/NotReady"),
        },
      ],
    },
    {
      name: "Login",
      path: "/login",
      beforeEnter: noRequireAuth(),
      component: () => import("@/views/authentication/BoxedLogin"),
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
