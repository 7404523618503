import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
// i18n
import i18n from './i18n/i18n.js'
import "vue-hljs/dist/style.css";
import 'sweetalert2/dist/sweetalert2.min.css';
  
Vue.use(VueFeather);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

import vueHljs from "vue-hljs";
import hljs from "highlight.js";


// Require Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')
require('froala-editor/js/plugins/table.min.js')
require('froala-editor/js/plugins/file.min.js')
require('froala-editor/js/plugins/image.min.js')
require('froala-editor/js/plugins/video.min.js')
require('froala-editor/js/plugins/draggable.min.js')

// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')
require('froala-editor/css/plugins/table.min.css')
require('froala-editor/css/plugins/file.min.css')
require('froala-editor/css/plugins/image.min.css')
require('froala-editor/css/plugins/video.min.css')
require('froala-editor/css/plugins/draggable.min.css')


// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)


//use
Vue.use(vueHljs, { hljs });
Vue.use(InstantSearch);
new Vue({ store, router,i18n, render: (h) => h(App) }).$mount("#app");
