import axios from "axios";
import base64 from 'base-64';


const API_URL = "https://backend.admin.hooh-iot.com/";
// const API_URL = "http://ec2-43-201-146-218.ap-northeast-2.compute.amazonaws.com/";
// const API_URL = "http://localhost:3300/";

const pswdVerifySwal = (swal, onSuccess) => {
    swal.fire({
        title: '인증',
        html: "요청하신 기능을 실행하기 위해서는 비밀번호 입력이 필요합니다.",
        input: 'password',
        icon: 'warning',
        inputAttributes: {
          autocapitalize: 'off',
          placeHolder: '비밀번호'
        },
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showLoaderOnConfirm: true,
        preConfirm: async (password) => {
          let pswdVerifyRes = await pswdVerify(password);
          if(pswdVerifyRes.error){
            swal.showValidationMessage(
                pswdVerifyRes.message
              )
              return false;
          }
          else{
              return true;
          }
      
      
          // return fetch(`//api.github.com/users/${login}`)
          //   .then(response => {
          //     if (!response.ok) {
          //       throw new Error(response.statusText)
          //     }
          //     return response.json()
          //   })
          //   .catch(error => {
          //     this.$swal.showValidationMessage(
          //       `Request failed: ${error}`
          //     )
          //   })
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          onSuccess();
        }
      })
}

const checkErrorResponseCode = async (code) => {
    if(code == 401){
        await localStorage.removeItem('ACCESS_TOKEN');
        await localStorage.removeItem('REFRESH_TOKEN');
        return true;
    }
    return false;
};

const makeHeaders = async () => {
    let headers = {};
    let accessToken = await localStorage.getItem("ACCESS_TOKEN");
    let refreshToken = await localStorage.getItem("REFRESH_TOKEN");

    if(accessToken != null){
        headers['Authorization'] = "Bearer " + accessToken;
        headers['refresh-token'] = refreshToken;
        
        accessToken = accessToken.split('.')[1];
        accessToken = base64.decode(accessToken);
        accessToken = JSON.parse(accessToken);

        if(Math.ceil(new Date().getTime()/1000) > accessToken.exp){
            let refreshTokenResponse = null;
            try{
                refreshTokenResponse = await axios.post(API_URL + "user/refreshToken", {}, {
                    headers: headers
                });
            }
            catch(e){
                refreshTokenResponse = false;
            }

            if(refreshTokenResponse != false && refreshTokenResponse.data.error == false){
                let newAccessToken = refreshTokenResponse.data.accessToken;
                let newRefreshToken = refreshTokenResponse.data.refreshToken;

                headers['Authorization'] = "Bearer " + newAccessToken;
                headers['refresh-token'] = newRefreshToken;

                await localStorage.setItem('ACCESS_TOKEN', newAccessToken);
                await localStorage.setItem('REFRESH_TOKEN', newRefreshToken);
            }else{
                await localStorage.removeItem('ACCESS_TOKEN');
                await localStorage.removeItem('REFRESH_TOKEN');

                headers = {
                    'Authorization': '',
                    'refresh-token': ''
                };
            }
        }
    }

    return headers;
};

const login = async (form) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "user/login", form, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const pswdChange = async (password, newPassword) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "user/pswdChange", {
            password: password,
            newPassword: newPassword
        }, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const dashboard = async () => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.get(API_URL + "util/dashboard", {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const profile = async () => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "user/profile", {}, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const unitList = async (page, search, dongSearch) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.get(API_URL + "unit/list?page="+page+(search != "" ? "&search="+search : "")+(dongSearch != "" ? "&dongSearch="+dongSearch : ""), {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const unitAdd = async (dong, ho, name, phone) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "unit/add", {
            dong: dong,
            ho: ho,
            name: name,
            phone: phone
        }, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const unitEdit = async (idx, dong, ho, name, phone) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "unit/edit", {
            idx: idx,
            dong: dong,
            ho: ho,
            name: name,
            phone: phone
        }, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const unitDelete = async (idx) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "unit/delete", {
            idx: idx
        }, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const unitAddExcel = async (file) => {
    try{
        let formData = new FormData();
        formData.append("excel", file);

        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "unit/addExcel", formData, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const unitPendingList = async (page, dongSearch) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.get(API_URL + "unit/pending/list?page="+page+(dongSearch != "" ? "&dongSearch="+dongSearch : ""), {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const unitPendingStatus = async (pendingId, status) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "unit/pending/status", {
            pendingId: pendingId,
            status: status
        }, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const dongList = async () => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.get(API_URL + "util/dongList", {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};

const pswdVerify = async (password) => {
    try{
        const axiosHeaders = await makeHeaders();
        const axiosRes = await axios.post(API_URL + "util/pswdVerify", {password: password}, {
            headers: axiosHeaders
        });
        return axiosRes.data;
    }
    catch(e){
        let isAuthError = await checkErrorResponseCode(e.response ? e.response.status : 401);
        if(isAuthError){
            return {
                error: true,
                loginRequired: true,
                message: e.message
            };
        }
        return {
            error: true,
            message: e.message
        };
    }
};


export {
    login,
    dashboard,
    profile,
    unitList,
    unitAdd,
    unitEdit,
    unitDelete,
    unitAddExcel,
    unitPendingList,
    unitPendingStatus,
    pswdChange,
    dongList,
    pswdVerify,
    pswdVerifySwal
};